import logo from "./assets/images/logo-trans.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="HT Bygg i Kungsbacka logotyp"
        />
        <h1>HT Bygg i Kungsbacka</h1>
        <p>
          Gör ditt nästa byggprojekt i Kungsbacka med omnejd till en framgång
          med HT Bygg.
        </p>
        <div>
          Vi är här för att säkerställa att ditt projekt genomförs med högsta
          kvalitet och professionalism.
        </div>
        <div>
          Med vår långa erfarenhet och djupgående kunskap inom byggsektorn, är
          vi väl rustade för att möta dina specifika behov och överträffa dina
          förväntningar.
        </div>
        <div>
          Ta första steget mot ett lyckat byggprojekt - kontakta oss idag för
          att diskutera dina planer.
        </div>
        <div>Besök oss gärna på Facebook</div>
        <div className="social-links">
          <a
            className="App-link"
            href="https://www.facebook.com/htbyggabse"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
        </div>
        <p>
          <a
            className="App-link"
            href="mailto:kontakt@htbygg-ab.se"
            rel="noopener noreferrer"
          >
            kontakt@htbygg-ab.se
          </a>
          <br />
          <a
            className="App-link"
            href="tel:0704209878"
            rel="noopener noreferrer"
          >
            0704-20 98 78
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
